import React from 'react'
import { Link } from 'gatsby'
import '../styles/header.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Navbar, NavDropdown, Dropdown } from 'react-bootstrap'
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse'
import { Nav } from 'react-bootstrap'
import logo from "../images/logo.svg"

const header = () => {

    return (
        <header class="header fixed-top">
            
            <Navbar expand="lg" className="">
            <Navbar.Brand><Link to="/" className="navbar_brand">
                <img src={logo} alt="logo" style={{height:"46px"}}></img></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar" className="ml-auto"/>
                    <NavbarCollapse className="nav_item">
                        <Nav className="ml-auto">
                            <Link to='/' className="nav_link" activeStyle={{ color: '#008FCC' }}>home</Link>
                        </Nav>
                        <Nav>
                        <Link to="/about" className="nav_link" activeStyle={{ color: '#008FCC' }}>about us</Link>
                        </Nav>
                            
                        {/* <Nav>
                             <Link to='/medicalTourism' className="nav_link" activeStyle={{ color: '#008FCC' }}>MEDICAL TOURISM</Link>
                        </Nav> */}
                        <Nav>
                            
                            <NavDropdown className="nav_link" activeStyle={{ color: '#008FCC' }} title="Services" id="collabsible-nav-dropdown">
                                <Dropdown.Item><Link to="/holidayPackages"className="nav_link sub-menu">holiday packages</Link></Dropdown.Item>
                                <Dropdown.Item><Link to="/medicalTourism"className="nav_link sub-menu">Medical Tourism</Link></Dropdown.Item>
                                {/* <Dropdown.Divider /> */}
                                
                                <Dropdown.Item><Link to="/healthPackages"className="nav_link sub-menu">Health packages</Link></Dropdown.Item>
                                <Dropdown.Item><Link to="/upcomingPage"className="nav_link sub-menu">Pilgrimage Tourism</Link></Dropdown.Item>
                                {/* <Dropdown.Item><Link to="/upcomingPage"className="nav_link sub-menu">Destination Wedding</Link></Dropdown.Item> */}
                                <Dropdown.Item><Link to="/upcomingPage"className="nav_link sub-menu">Hajj & Umrah</Link></Dropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Link to="/blog" className="nav_link" activeStyle={{ color: '#008FCC' }}>blog</Link>
                        </Nav>
                        <Nav>
                            <Link to="/contact" className="nav_link mr-5" activeStyle={{ color: '#008FCC' }}>contact us</Link>
                        </Nav>

                        <Nav>
                            <Link to="https://rzp.io/l/b8IT36G" className="btn btn-primary" activeStyle={{ color: '#008FCC' }}>Pay Online</Link>
                        </Nav>
                        {/* <Nav>
                            <Link to="/career" className="nav_link" activeStyle={{ color: '#008FCC' }}>career</Link>
                        </Nav> */}
                        
                    </NavbarCollapse>
               
            </Navbar>
        </header>
    )
}
export default header
