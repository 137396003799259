import React from "react"
import { Link } from "gatsby"
import '../styles/footer.css'
import { Row, Col} from "react-bootstrap"
// import logo from '../images/logo.png' 
import locationIcon from '../images/2ndColVector1.png'
import phoneIcon from '../images/2ndColVector2.png'
import emailIcon from '../images/2ndColVector3.png'
import instaIcon from '../images/3rdColVector1.png'
import fbIcon from '../images/3rdColVector2.png'
import youtubeIcon from '../images/3rdColVector3.png'
import logo from "../images/logo.svg"




const Footer = () => {
    return (
        <footer>
            {/* <Container fluid>
                    <Nav> */}
                        {/* <img style={{width:"150px",height:"30px"}} src={logo} alt="logo"></img> */}
                        {/* <h3 className="foot-header">AUSFLUG HOLIDAYS</h3>
                    </Nav> */}
                {/* <Row>
                    
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className="ftr_nav_item">
                        
                            <Nav className="ftr_nav">
                                <Link to='/' className="ftr_nav_link">Home</Link>
                            </Nav>
                            <Nav className="ftr_nav">
                                <Link to="/about" className="ftr_nav_link">About</Link>
                            </Nav>
                            <Nav className="ftr_nav">
                                <Link to='/medicalTourism' className="ftr_nav_link">Treatments</Link>
                            </Nav>
                            {/* <Nav className="ftr_nav">
                                <Link to="/packages" className="ftr_nav_link">packages</Link> 
                            </Nav> */}
                            {/* <Nav className="ftr_nav">
                                <Link to="/healthpackages" className="ftr_nav_link">Health Packages</Link>
                            </Nav>
                            <Nav className="ftr_nav">
                                <Link to="/blog" className="ftr_nav_link">Blog</Link>
                            </Nav>
                            <Nav className="ftr_nav">
                                <Link to="/contact" className="ftr_nav_link">Contact</Link>
                            </Nav>    
                            <Nav className="ftr_nav">
                                <Link to="/careers" className="ftr_nav_link">Careers</Link>
                            </Nav>     */}
                            {/* <Nav className="ftr_nav">
                                <Link to="/customized_packages" className="ftr_nav_link">career</Link>
                            </Nav>                                       */}
                    {/* </Col>

                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> */}
                        {/* <Form accept-charset="utf-8" action="https://formspree.io/xrgobpww" method="post">
                            <Form.Group controlId="exampleForm.ControlInput1">
                                
                                <Form.Control type="Text" placeholder="Name" name="name" id="full-name" required/>
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlInput2">
                                
                                <Form.Control type="email" placeholder="Email" name="_replyto" id="email-address" required/>
                            </Form.Group>
                            
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                
                                <Form.Control as="textarea" rows="3" placeholder="message" name="message" id="message" required/>
                            </Form.Group>

                            <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission"></input>
                            <Button variant="primary" type="submit" className="button">
                                Submit
                            </Button>
                            </Form> */}
                            {/* <Row>
                                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <img src="../../mdi_location_on.svg" alt="location icon" className="ftr_icon"></img>
                                </Col>
                                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                                    <p>Opp. Govt Law College,<br></br>Calicut, Kerala</p>
                                </Col>
                                
                                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <img src="../../mdi_location_on.svg" alt="location icon" className="ftr_icon"></img>
                                </Col>
                                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                                    <p>Opp. Govt Law College,<br></br>Calicut, Kerala</p>
                                </Col>

                                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <img src="../../el_phone.svg" alt="phone icon" className="ftr_icon"></img>
                                </Col>  
                                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                                    <a href="tel:‎+914953554863" style={{fontSize:"medium"}}>‎+91 495 355 4863</a>
                                    <br></br>
                                    <a href="tel:‎‎+919633574815" style={{fontSize:"medium"}}>‎+91 96335 74815</a>

                                </Col>

                                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <img src="../../gridicons_mail.svg" alt="email icon" className="ftr_icon"></img>
                                </Col>
                                <Col xl={11} lg={11} md={10} sm={11} xs={11}>
                                    <a href="mailto: info@ausflugholidays.com"><p>info@ausflugholidays.com</p></a>
                                </Col>
                            </Row>
                        </Col>


                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Row className="ftr_contact" > */}

                                {/* Instagram */}

                                {/* <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <img src={instaIcon} alt="insta icon" className="ftr_icon"></img>
                                </Col>
                                <Col xl={11} lg={11} md={10} sm={11} xs={11}>
                                    <a href="https://www.instagram.com/ausflugholidays"><p>ausflugholidays</p></a>
                                </Col> */}

                                {/* Facebook */}

                                {/* <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <img src={fbIcon} alt="insta icon" className="ftr_icon"></img>
                                </Col>
                                <Col xl={11} lg={11} md={10} sm={11} xs={11}>
                                    <a href="https://www.facebook.com/ausflugholidays"><p>ausflugholidays</p></a>
                                </Col> */}
                                
                                {/* Youtube */}

                                {/* <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <img src={youtubeIcon} alt="insta icon" className="ftr_icon"></img>
                                </Col>

                                <Col xl={11} lg={11} md={10} sm={11} xs={11}>
                                    <a href="#"><p>ausflugholidays</p></a>
                                </Col> */}
                                                    
                            {/* </Row>
                        </Col>
                </Row> */}
                        
                        
                       
                

               

                {/* <div className="ftr_contact_list">
                    
                </div> */}
            {/* </Container> */}

            <footer style={{marginTop:"180px",padding:"60px 80px 30px 80px", boxShadow:"0px -3px 8px #8080804f"}}>
                <center><div className="footer-content">
                    <div>
                    <Link to="/">
                        <div style={{display:"flex"}}>
                            <img style={{width:"280px", marginLeft:"-40px"}} src={logo} alt="" />
                        </div>
                    </Link>
                    <Row >
                        <Col className="col-12 col-sm-12 col-md-4" style={{textAlign:"start",padding:"10px 0 10px 0", display:"flex", alignItems:"center"}}>
                            <div>
                                
                                <Link to="/"><h5>Treatments</h5></Link>
                                <Link to="/"><h5>Health Packages</h5></Link>
                                <Link to="/"><h5>Blog</h5></Link>
                                
                            </div>
                        </Col>
                            
                        <Col className="col-12 col-sm-12 col-md-4" style={{textAlign:"start",padding:"10px 0 10px 0"}}>
                            <div>
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src={locationIcon} alt="location icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>157 Carlls Path, Deer Park,<br/> New York,USA 11729</h5>
                                </div></Link>
                                
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src={locationIcon} alt="location icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>Opp. Govt Law College,<br/>Calicut, Kerala</h5>
                                </div></Link>
                                
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src={phoneIcon} alt="phone icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>+91 8714 535 815 <br/>+91 9633 574 815</h5>
                                </div></Link>
                                
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src={emailIcon} alt="email icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>info@ausflugholidays.com</h5>
                                </div></Link>
                            </div>
                        </Col>
                        
                        <Col className="social-media-icons col-12 col-sm-12 col-md-4" style={{display:"flex",justifyContent:"start",alignItems:"center",padding:"10px 0 10px 0"}}>
                            <div>
                                <Link to="https://www.instagram.com/ausflugholidays"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src={instaIcon} alt="insta icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>ausflugholidays</h5>
                                </div></Link>
                                
                                <Link to="https://www.facebook.com/ausflugholidays"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                <img src={fbIcon} alt="insta icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>ausflugholidays</h5>
                                </div></Link>
                                
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                <img src={youtubeIcon} alt="insta icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>ausflugholidays</h5>
                                </div></Link>
                            </div>
                        </Col>
                    </Row>
                    <div className="flex justify-center items-center">
                        <h1 className="copyrightText">© 2021 © Ausflug Holidays Private Limited ©</h1>
                    </div>
                    </div>
                </div></center>
            </footer>


        </footer>
    )
}

export default Footer