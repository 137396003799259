import React from 'react'
import Header from './header'
import Footer from './footer' 
import '../styles/layout.css'
import {Helmet} from 'react-helmet'

const layout = (probs) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-f"></meta>
                <title>Ausflug Holidays | Calicut | Tour Packages | Medical Tourism</title>
            </Helmet>
            <Header />
            {probs.children}
            <Footer />
        </div>
    )
}
export default layout